import "./App.css";
import Header from "./header/Header";
import Home from "./home/Home";
import About from "./about/About";
// import Services from "./services/Services";
import Portfolio from "./potfolio/Portfolio";
import Contact from "./contact/Contact";

function App() {
  return (
    <div className="App">
      <Header />
      <div>
        <Home />
        <About />
        {/* <Services /> */}
        <Portfolio />
        <Contact />
      </div>
    </div>
  );
}

export default App;
// npm run build
// firebase deploy
