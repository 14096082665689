import React, { useState, useEffect } from "react";
import "./Contact.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Particles from "react-tsparticles";
import ReactTypingEffect from "react-typing-effect";

function Contact() {
  const [userContactData, setUserContactData] = useState(null);
  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);

  useEffect(() => {
    setUserContactData({
      name: "",
      email: "",
      subject: "",
      message: "",
      phone: "",
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    //fetch(`http://127.0.0.1:8080/api/k0rd1k`, {
    fetch(`https://petro-store-server.herokuapp.com/api/k0rd1k`, {
      method: "POST",
      body: JSON.stringify(userContactData),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((msg) => {
        handleShow(msg);
        //if (msg) console.log(msg);
      });
  };
  const handleClose = () => {
    setUserContactData({
      name: "",
      email: "",
      subject: "",
      message: "",
      phone: "",
    });
    setShow(false);
    //props.history.goBack(); redirect to previous page
  };
  const handleShow = (msg) => {
    setModalMessage(msg);
    setShow(true);
  };

  const handleChange = (e) => {
    let target = e.currentTarget; // the element that initiated the event
    let value = target.value; // its value
    let name = target.name; // its name
    let newUserData = { ...userContactData }; // preform a "shallow" clone of productData
    newUserData[name] = value; // update the associated property for the control
    setUserContactData(newUserData); // set the new product data
  };

  return (
    <div className="contact sections" id="contact">
      <h1 className="contact-title">CONTACT US</h1>

      <Container fluid="xxl">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Report:</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Form onSubmit={handleSubmit}>
          <div className="contact-form-div">
            <Row xs={1} sm={2}>
              <Col>
                <InputGroup className="mb-3">
                  <FormControl
                    className="shadow-none"
                    placeholder="Name*"
                    name="name"
                    required
                    value={userContactData ? userContactData.name : ""}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3 shadow-none">
                  <FormControl
                    className="shadow-none"
                    placeholder="Phone*"
                    name="phone"
                    required
                    value={userContactData ? userContactData.phone : ""}
                    aria-label="Username"
                    onChange={handleChange}
                  />
                </InputGroup>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    className="shadow-none"
                    type="email"
                    name="email"
                    value={userContactData ? userContactData.email : ""}
                    placeholder="Email"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <InputGroup className="mb-3">
                  <FormControl
                    className="shadow-none"
                    placeholder="Subject"
                    aria-label="Subject"
                    name="subject"
                    value={userContactData ? userContactData.subject : ""}
                    onChange={handleChange}
                  />
                </InputGroup>
                <InputGroup>
                  <FormControl
                    className="shadow-none form-textarea"
                    placeholder="Your message here*"
                    as="textarea"
                    name="message"
                    required
                    aria-label="With textarea"
                    value={userContactData ? userContactData.message : ""}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Col>
            </Row>
          </div>
          <Button variant="warning" type="submit">
            Submit
          </Button>
        </Form>

        <div className="contact-typingEffect-div">
          <ReactTypingEffect
            className="contact-typingEffect"
            speed={100}
            eraseSpeed={100}
            eraseDelay={5000}
            typingDelay={500}
            cursor=" "
            text={[
              "Let's do it...",
              "Let's Create...",
              "Let's Build...",
              "Let's Improve...",
            ]}
          />
        </div>
        <div className="footer">
          <Row xs={1} sm={2}>
            <Col>
              <div className="footer-left">
                bricks.toronto@gmail.com<br></br>
                416-571-7832<br></br>
                647-355-0003
              </div>
            </Col>
            <Col>
              <div className="footer-right">
                {" "}
                follow us{" "}
                <a href="https://www.facebook.com/bricksgta">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-facebook"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg>
                </a>{" "}
                <a href="https://www.instagram.com/bricksgta/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="rgb(248,81,86)"
                    class="bi bi-instagram"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                  </svg>
                </a>{" "}
                <br></br>
                Copyright &copy; {new Date().getFullYear()} Bricks GTA
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Contact;
