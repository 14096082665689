import React from "react";
import "./Home.css";
import ReactTypingEffect from "react-typing-effect";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import Particles from "react-tsparticles";

import Image from "react-bootstrap/Image";
import Photo4 from "./4.jpg";
import Photo5 from "./5.jpg";
import Photo6 from "./6.jpg";
import Photo7 from "./7.jpg";

function Home() {
  return (
    <div className="home sections" id="home">
      <div className="home_upper_photos">
        <Image className="img1" fluid thumbnail src={Photo4}></Image>
        <div className="home_title welcome">Welcome</div>

        <Image className="img2" fluid thumbnail src={Photo5}></Image>
      </div>
      <div className="home_title">
        We are <span className="home_mobile_no_display">renovation</span>{" "}
        contractors
        <span className="home_mobile_no_display">
          {" "}
          in the Toronto and GTA region
        </span>
        .<br></br>{" "}
        <Image
          className="home_mobile_display_img "
          fluid
          thumbnail
          rounded
          src={Photo4}
        ></Image>
        <br></br>{" "}
        <span className="home_name">Our focus is brick and stone veneer.</span>
      </div>
      <div className="home_lower_photos">
        <Image className="img3" fluid thumbnail src={Photo6}></Image>
        <Button variant="warning" size="lg" href="#contact">
          BOOK A FREE ESTIMATE
        </Button>{" "}
        <Image className="img4" fluid thumbnail src={Photo7}></Image>
      </div>
    </div>
  );
}

export default Home;
