import React from "react";
import "./About.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Photo from "./wall(bricks).jpg";
import Card1 from "./card1.png";
import Card2 from "./card2.png";

function About() {
  return (
    <div className="about sections" id="about">
      <h3>About</h3>
      <Container>
        {" "}
        <Row xs={1} sm={2}>
          <Col>
            <div className="about-photo">
              <Image fluid thumbnail src={Card2}></Image>
            </div>

            <div className="about-photo">
              <Image fluid thumbnail src={Card1}></Image>
            </div>
          </Col>
          <Col id="about-right">
            <div>
              <p className="about-p">
                Our priorities: PROFESSIONALISM, QUALITY, and CUSTOMER TIME.
                <br></br> <br></br> Have been working in the construction
                industry for over 10 years! During this time, more than 100
                objects of various levels of complexity were completed.<br></br>
                <br></br> Like any other company, we made some mistakes, but we
                managed to learn from them and improved the quality of our work
                over time. Thus, our diverse experience is the foundation of our
                professionalism!
                <br></br>
                <br></br> Our reputation is our happy clients, and we really
                care about our reputation.
              </p>
              <h6>Why Bricks GTA:</h6>
              <p className="about-p">
                - Ability to hear the client and give a valuable advice.
                <br></br>- Innovative Solutions and Technologies. <br></br>-
                Efficiently and On time.<br></br>- Free Estimate.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;
