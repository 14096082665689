import "./Header.css";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-scroll";

import Image from "react-bootstrap/Image";
// import Ukr_flag from "./ukr.png";
// import Logo1 from "./logo.jpg";
// import Logo2 from "./LogoPng.png";
// import Logo3 from "./FullLogoWhite.png";
import Logo4 from "./2.png";

function Header() {
  const removeStyles = () => {
    console.log("here");
  };
  return (
    <div className="header">
      <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark" fixed="top">
        <Container>
          {/* <a
            className="donation_link"
            href="https://bank.gov.ua/en/about/support-the-armed-forces"
            target="_blank"
          >
            Help Ukraine
          </a> */}
          <Navbar.Brand href="#home">
            <Image className="ukr-flag" src={Logo4}></Image>
          </Navbar.Brand>

          <Navbar.Brand href="#home">Bricks GTA</Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="justify-content-end">
              <Nav.Link href="#home">
                <span className="header_navbar_collapsed">HOME</span>
                <Link className="header_navbar_not_collapsed" spy={true} to="home" smooth={true} duration={100}>
                  <span className="header_responsive_home_link">HOME</span>
                </Link>
              </Nav.Link>
              <Nav.Link href="#about">
                <span className="header_navbar_collapsed">ABOUT</span>
                <Link className="header_navbar_not_collapsed" spy={true} to="about" smooth={true} duration={100}>
                  ABOUT<span className="header_responsive_about_ME"> US</span>
                </Link>{" "}
              </Nav.Link>
              {/* <Nav.Link href="#services">SERVICES</Nav.Link> */}
              <Nav.Link href="#portfolio">
                <span className="header_navbar_collapsed">PORTFOLIO</span>
                <Link className="header_navbar_not_collapsed" spy={true} to="portfolio" smooth={true} duration={100}>
                  PORTFOLIO
                </Link>
              </Nav.Link>
              <Nav.Link href="#contact">
                <span className="header_navbar_collapsed">CONTACT</span>

                <Link className="header_navbar_not_collapsed" spy={true} to="contact" smooth={true} duration={100}>
                  CONTACT
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
