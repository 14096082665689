import React from "react";
import "./Portfolio.css";

import Container from "react-bootstrap/Container";

import Carousel from "react-bootstrap/Carousel";

import window1 from "./house(window)/1.jpg";
import window2 from "./house(window)/2.jpg";
import window3 from "./house(window)/3.jpg";

import pizza1 from "./pizza/1.jpg";
import pizza2 from "./pizza/2.jpg";
import pizza3 from "./pizza/3.jpg";
import pizza4 from "./pizza/4.jpg";
import pizza5 from "./pizza/5.jpg";
import pizza6 from "./pizza/6.jpg";
import pizza7 from "./pizza/7.jpg";
import pizza8 from "./pizza/8.jpg";
import pizza9 from "./pizza/9.jpg";
import pizza10 from "./pizza/10.jpg";
import pizza11 from "./pizza/11.jpg";
import pizza12 from "./pizza/12.jpg";
import pizza13 from "./pizza/13.jpg";
import pizza14 from "./pizza/14.jpg";
import pizza15 from "./pizza/15.jpg";
import pizza16 from "./pizza/16.jpg";

import floor1 from "./floor/1.jpg";
import floor2 from "./floor/2.jpg";
import floor3 from "./floor/3.jpg";
import floor4 from "./floor/4.jpg";
import floor5 from "./floor/5.jpg";
import floor6 from "./floor/6.jpg";
import floor7 from "./floor/7.jpg";
import floor8 from "./floor/8.jpg";
import floor9 from "./floor/9.jpg";
import floor10 from "./floor/10.jpg";
import floor11 from "./floor/11.jpg";
import floor12 from "./floor/12.jpg";
import floor13 from "./floor/13.jpg";
import floor14 from "./floor/14.jpg";
import floor15 from "./floor/15.jpg";
import floor16 from "./floor/16.jpg";
import floor17 from "./floor/17.jpg";

import homer1 from "./homer/1.jpg";
import homer2 from "./homer/2.jpg";
import homer3 from "./homer/3.jpg";

import house1 from "./house/1.jpg";
import house2 from "./house/2.jpg";
import house3 from "./house/3.jpg";
import house4 from "./house/4.jpg";
import house5 from "./house/5.jpg";
import house6 from "./house/6.jpg";
import house7 from "./house/7.jpg";
import house8 from "./house/8.jpg";
import house9 from "./house/9.jpg";
import house10 from "./house/10.jpg";
import house11 from "./house/11.jpg";
import house12 from "./house/12.jpg";
import house13 from "./house/13.jpg";
import house14 from "./house/night_front.jpg";
import house15 from "./house/night_garage.jpg";
import house16 from "./house/night_side.jpg";

function Portfolio() {
  return (
    <div className="portfolio sections" id="portfolio">
      <Container>
        <div className="portfolio-tiltle portfolio-tiltle_responsive_off">
          <h1>Recent Projects</h1>
        </div>

        <div className="portfolio-clients">
          <div className="portfolio-carousel">
            <Carousel>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={house1} alt="First slide" />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={house14}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={house3} alt="First slide" />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={house2} alt="First slide" />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={house4} alt="First slide" />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={house5} alt="First slide" />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={house6} alt="First slide" />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={house7} alt="First slide" />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={house8} alt="First slide" />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={house9} alt="First slide" />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={house10}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={house11}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={house12}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={house13}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={house15}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={house16}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="portfolio-carousel">
            <Carousel>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={pizza16}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h1>Pizza Pizza project</h1>
                  <p>Toronto Island</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={pizza2} alt="First slide" />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={pizza3} alt="First slide" />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={pizza4} alt="First slide" />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={pizza5} alt="First slide" />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={pizza6} alt="First slide" />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={pizza7} alt="First slide" />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={pizza8} alt="First slide" />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={pizza9} alt="First slide" />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={pizza10}
                  alt="First slide"
                />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={pizza11}
                  alt="First slide"
                />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={pizza12}
                  alt="First slide"
                />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={pizza13}
                  alt="First slide"
                />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={pizza14}
                  alt="First slide"
                />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={pizza15}
                  alt="First slide"
                />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={pizza1} alt="First slide" />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="portfolio-carousel">
            <Carousel>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={window1}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h1>House renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={window2}
                  alt="First slide"
                />
                <Carousel.Caption>
                  {" "}
                  <h1>In progress...</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={window3}
                  alt="First slide"
                />
                <Carousel.Caption>
                  {" "}
                  <h1>Before</h1>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="portfolio-carousel">
            <Carousel>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={floor1} alt="First slide" />
                <Carousel.Caption>
                  <h1>Floor renovation project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={floor2} alt="First slide" />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={floor3} alt="First slide" />
                <Carousel.Caption></Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={floor4} alt="First slide" />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={floor4} alt="First slide" />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={floor5} alt="First slide" />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={floor6} alt="First slide" />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={floor7} alt="First slide" />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={floor8} alt="First slide" />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={floor9} alt="First slide" />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={floor10}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={floor11}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={floor12}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={floor13}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={floor14}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={floor15}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={floor16}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img
                  className="d-block w-100"
                  src={floor17}
                  alt="First slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="portfolio-carousel">
            <Carousel>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={homer1} alt="First slide" />
                <Carousel.Caption>
                  <h1>Apartment project</h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={homer2} alt="First slide" />
                <Carousel.Caption>
                  {" "}
                  <h1></h1>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={10000}>
                <img className="d-block w-100" src={homer3} alt="First slide" />
                <Carousel.Caption>
                  {" "}
                  <h1>In progress...</h1>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Portfolio;
